import { observer } from "mobx-react-lite";
import {
  Dialog,
  DialogContent,
} from "../../../shadcn-components/dialog";
import { Check, TicketX } from "lucide-react";
import { Button } from "../../../shadcn-components/button";
interface CancelRegistrationDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onConfirmCancel: () => void;
}

export const CancelRegistrationDialog: React.FC<CancelRegistrationDialogProps> =
  observer(({ open, onOpenChange, onConfirmCancel }) => {
    return (
      <Dialog open={open} onOpenChange={onOpenChange}>
        <DialogContent className="tw-bg-white tw-rounded-2xl tw-w-[90%] tw-mx-auto ">
          <div className="">
            <div className="tw-flex tw-h-12 tw-w-12 tw-items-center tw-justify-center tw-rounded-full tw-bg-[#131315] tw-mb-4">
              <TicketX className="tw-h-6 tw-w-6 tw-text-white" />
            </div>

            <h2 className="tw-text-2xl tw-font-semibold tw-mb-3">
              Cancel Registration
            </h2>

            <p className="tw-text-gray-500 tw-text-lg">
              Swipe to cancel your registration. We'll notify the host that you
              won't be able to attend.
            </p>
          </div>

          <div className="tw-flex tw-justify-center tw-mt-6">
            <Button
              variant="outline"
              className="!tw-rounded-full tw-w-12 tw-h-12 tw-p-0 !tw-bg-black"
              onClick={onConfirmCancel}
            >
              <Check className="tw-h-6 tw-w-6 tw-text-white" />
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    );
  });
