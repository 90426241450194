import { Check, Image as ImageIcon, X } from "lucide-react";
import Cropper from "react-easy-crop";
import { Button } from "../../shadcn-components/button";
import { useState } from "react";

interface Area {
  x: number;
  y: number;
  width: number;
  height: number;
}

interface CroppedAreaPixels extends Area {}
interface CroppedArea extends Area {}

interface ImageUploaderProps {
  onImageSelect: (file: File) => void;
  previewUrl?: string | null;
  className?: string;
  buttonText?: string;
  height?: number;
}

interface ImageCropperProps {
  image: string;
  aspectRatio: number;
  onConfirm: (croppedFile: File) => void;
  onCancel: () => void;
  containerClassName?: string;
  height?: number;
}

// Reusable component for image upload button/preview
export const ImageUploader: React.FC<ImageUploaderProps> = ({
  onImageSelect,
  previewUrl,
  className = "",
  buttonText,
  height,
}) => {
  const handleUpload = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.onchange = (e) => {
      const file = (e.target as HTMLInputElement).files?.[0];
      if (file) {
        onImageSelect(file);
      }
    };
    input.click();
  };

  const containerStyle = height ? { height: `${height}px` } : undefined;

  return (
    <div
      className={`tw-relative tw-w-full ${className}`}
      style={containerStyle}
    >
      {previewUrl ? (
        <>
          <img
            src={previewUrl}
            alt="Preview"
            className="tw-w-full tw-h-full tw-object-cover tw-rounded-lg"
          />
          <Button
            variant="ghost"
            size="icon"
            className="tw-absolute tw-bottom-4 tw-right-4 tw-bg-white/80"
            onClick={handleUpload}
            type="button"
          >
            <ImageIcon className="tw-h-4 tw-w-4" />
          </Button>
        </>
      ) : (
        <div className="tw-h-full tw-relative">
          <div className="tw-h-full tw-relative tw-rounded-lg tw-bg-gray-100 tw-flex tw-items-center tw-justify-center">
            <Button
              variant="ghost"
              onClick={handleUpload}
              type="button"
              className="tw-w-full tw-h-full tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-2"
            >
              <ImageIcon className="tw-h-6 tw-w-6 tw-text-gray-400" />
              <span className="tw-text-xs tw-text-gray-500">{buttonText}</span>
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export const ImageCropper: React.FC<ImageCropperProps> = ({
  image,
  aspectRatio,
  onConfirm,
  onCancel,
  containerClassName = "",
  height,
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [completedCrop, setCompletedCrop] = useState<{
    croppedArea: CroppedArea;
    croppedAreaPixels: CroppedAreaPixels;
  } | null>(null);

  const onCropComplete = (
    croppedArea: CroppedArea,
    croppedAreaPixels: CroppedAreaPixels
  ) => {
    setCompletedCrop({ croppedArea, croppedAreaPixels });
  };

  const handleConfirm = async () => {
    if (!completedCrop) return;

    try {
      const canvas = document.createElement("canvas");
      const imgElement = new Image();
      imgElement.src = image;

      await new Promise((resolve) => {
        imgElement.onload = resolve;
      });

      const scaleX = imgElement.naturalWidth / imgElement.width;
      const scaleY = imgElement.naturalHeight / imgElement.height;

      // Calculate width based on height and aspect ratio
      const outputHeight = height || completedCrop.croppedAreaPixels.height;
      const outputWidth = outputHeight * aspectRatio;

      canvas.width = outputWidth;
      canvas.height = outputHeight;

      const ctx = canvas.getContext("2d");
      if (!ctx) {
        throw new Error("No 2d context");
      }

      ctx.drawImage(
        imgElement,
        completedCrop.croppedAreaPixels.x * scaleX,
        completedCrop.croppedAreaPixels.y * scaleY,
        completedCrop.croppedAreaPixels.width * scaleX,
        completedCrop.croppedAreaPixels.height * scaleY,
        0,
        0,
        canvas.width,
        canvas.height
      );

      const blob = await new Promise<Blob>((resolve) =>
        canvas.toBlob((blob) => resolve(blob!), "image/jpeg", 0.95)
      );

      const fileName = `cropped-${Date.now()}.jpg`;
      const croppedFile = new File([blob], fileName, { type: "image/jpeg" });

      onConfirm(croppedFile);
    } catch (e) {
      console.error("Error in image cropping:", e);
    }
  };

  const containerStyle = height ? { height: `${height}px` } : undefined;

  return (
    <div
      className={`tw-relative tw-w-full ${containerClassName}`}
      style={containerStyle}
    >
      <div className="tw-relative tw-bg-gray-900 tw-h-full">
        <div className="tw-relative tw-h-full">
          <Cropper
            image={image}
            crop={crop}
            zoom={zoom}
            aspect={aspectRatio}
            onCropChange={setCrop}
            onZoomChange={setZoom}
            onCropComplete={onCropComplete}
            cropShape="rect"
            classes={{ containerClassName: "tw-h-full" }}
          />
        </div>
      </div>
      <div className="tw-absolute tw-flex tw-gap-2 tw-z-50 tw-bottom-4 tw-right-4">
        <Button
          variant="outline"
          size="icon"
          onClick={onCancel}
          className="tw-bg-white !tw-border-[#EDEDEB]"
          type="button"
        >
          <X className="tw-h-4 tw-w-4" />
        </Button>
        <Button
          variant="default"
          size="icon"
          onClick={handleConfirm}
          className="tw-bg-indigo-500 hover:tw-bg-indigo-600 !tw-border-[#EDEDEB]"
          type="button"
        >
          <Check className="tw-h-4 tw-w-4" />
        </Button>
      </div>
    </div>
  );
};
