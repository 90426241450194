import { MapPin, ChevronRight } from "lucide-react";
import { useState } from "react";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../shadcn-components/form";
import { LocationSearchView } from "./location-search-view";
import { Button } from "../../../shadcn-components/button";
import { LocationDetails } from "../api/address/autocomplete-service";
import { LocationValue } from "../stores/create-event-store";

interface LocationFieldProps {
  form: any;
  onLocationSelect: (location: LocationDetails) => void;
  defaultValue?: LocationDetails;
}

const LocationField = ({ form, onLocationSelect }: LocationFieldProps) => {
  const [showSearch, setShowSearch] = useState(false);

  return (
    <>
      <FormField
        control={form.control}
        name="location"
        render={({ field }) => (
          <FormItem className={"tw-text-left tw-font-medium"}>
            <FormLabel>Location</FormLabel>
            <FormControl>
              <Button
                type="button"
                variant="outline"
                className="tw-w-full tw-justify-between tw-h-auto tw-px-4 tw-py-3 !tw-border-none"
                onClick={() => setShowSearch(true)}
              >
                <div className="tw-flex tw-items-start tw-gap-3">
                  <MapPin className="tw-h-5 tw-w-5 tw-mt-0.5 tw-text-gray-500" />
                  <div className="tw-flex tw-flex-col tw-items-start tw-gap-0.5">
                    {field.value?.primaryAddress ? (
                      <>
                        <span className="tw-font-medium">
                          {field.value.primaryAddress}
                        </span>
                        <span className="tw-text-sm tw-text-gray-500">
                          {field.value.secondaryAddress}
                        </span>
                      </>
                    ) : (
                      <span className="tw-text-gray-500">Select location</span>
                    )}
                  </div>
                </div>
                <ChevronRight className="tw-h-5 tw-w-5 tw-text-gray-400" />
              </Button>
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      {showSearch && (
        <LocationSearchView
          onClose={() => setShowSearch(false)}
          onSelect={(locationDetails: LocationDetails) => {
            const location: LocationValue = {
              primaryAddress: locationDetails.primaryAddress,
              secondaryAddress: locationDetails.secondaryAddress,
              latitude: locationDetails.latitude,
              longitude: locationDetails.longitude,
            };

            // Update form
            form.setValue("location", location, {
              shouldValidate: true,
              shouldDirty: true,
            });

            // Update store
            onLocationSelect(location);

            setShowSearch(false);
          }}
          initialValue={form.getValues("location")?.primaryAddress}
        />
      )}
    </>
  );
};

export default LocationField;
