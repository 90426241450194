import { observer } from "mobx-react-lite";
import instagramIcon from "../../static/instagram.svg";
import twitterIcon from "../../static/twitter.svg";
import youtubeIcon from "../../static/youtube.svg";
import whatsappIcon from "../../static/whatsapp-black.svg";
import { Globe } from "lucide-react";
import {
  FormItem,
  FormControl,
  FormMessage,
} from "../../shadcn-components/form";
import { Input } from "../../shadcn-components/input";

interface SocialMediaInputProps {
  platform: string;
  field: any;
}

export const SocialMediaInput: React.FC<SocialMediaInputProps> = observer(
  ({ platform, field }) => {
    const getIcon = (platform: string) => {
      switch (platform) {
        case "instagram":
          return (
            <img
              src={instagramIcon}
              alt="Instagram"
              className="tw-w-5 tw-h-5"
            />
          );
        case "whatsapp":
          return (
            <img src={whatsappIcon} alt="Instagram" className="tw-w-5 tw-h-5" />
          );
        case "youtube":
          return (
            <img src={youtubeIcon} alt="Instagram" className="tw-w-5 tw-h-5" />
          );
        case "twitter":
          return (
            <img src={twitterIcon} alt="Instagram" className="tw-w-5 tw-h-5" />
          );
        case "website":
          return <Globe className="tw-w-5 tw-h-5" />;
        default:
          return null;
      }
    };

    return (
      <FormItem>
        <FormControl>
          <div className="tw-relative">
            <div className="tw-absolute tw-left-4 tw-top-1/2 -tw-translate-y-1/2">
              {getIcon(platform)}
            </div>
            <Input
              {...field}
              className="tw-h-12 !tw-rounded-[34px] tw-pl-12"
              placeholder={platform.charAt(0).toUpperCase() + platform.slice(1)}
            />
          </div>
        </FormControl>
        <FormMessage />
      </FormItem>
    );
  }
);
