import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ArrowLeft, ChevronLeft, Settings } from "lucide-react";
import { Button } from "../shadcn-components/button";
import profileSettingsStore from "../stores/profile-settings-store";
import { observer } from "mobx-react-lite";
import { communityStore } from "../stores/community-store";
import { authStore } from "../stores/auth-store";
import { BottomNav } from "./bottom-nav";

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = observer(({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { user } = authStore;

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleSettingsClick = () => {
    profileSettingsStore.openSettings();
  };

  const getHeaderInfo = () => {
    const path = location.pathname.slice(1);
    const pathParts = path.split("/");

    switch (true) {
      case path === "":
        return { show: false };
      case path === "join":
        return { show: true, title: "Login / Sign Up" };
      case path === "verify":
        return { show: true, title: "Verify your number" };
      case path === "onboarding-profile":
        return { show: true, title: " Let us get to know you" };
      case path === "about-us":
      case path === "explore":
        return { show: true, title: "Delhi NCR" };
      case path === "c/new":
        return { show: true, title: "Create a community" };
      case path === "me":
        return { show: true, title: "Your Profile", showSettingsIcon: true };
      case path === "calendar":
        return { show: true, title: "Delhi NCR" };
      case path === "e/new":
        return { show: true, title: "Create New Event" };
      case pathParts[1] === "manage":
        return {
          show: true,
          breadcrumbs: [
            { title: "Manage Community", path: `/${pathParts[0]}/manage` },
            { title: pathParts[2] === "admins" ? "Manage Admins" : undefined },
          ],
        };
      case pathParts[0] === "e":
        return { show: false };
      case path === "search":
        return { show: false, title: "" };
      case pathParts[0] === communityStore.currentCommunityViewName:
        return { show: false };
      default:
        if (!["join", "register"].includes(path)) {
          return { show: true, title: communityStore.currentCommunityViewName };
        }
        return { show: false, title: "" };
    }
  };

  const shouldShowBottomNav = () => {
    const noNavPaths = ["login", "verify", "onboarding-profile"];
    return !noNavPaths.includes(location.pathname.slice(1));
  };

  const headerInfo = getHeaderInfo();

  return (
    <div className="tw-flex tw-min-h-screen">
      <div className="tw-flex tw-flex-col tw-flex-grow tw-min-w-0">
        {headerInfo.show && (
          <header className="tw-flex tw-items-center tw-pt-4 tw-px-4 md:tw-p-6">
            <Button
              variant="secondary"
              size="icon"
              className="tw-mr-4 !tw-rounded-3xl"
              onClick={handleBackClick}
            >
              <ChevronLeft />
            </Button>
            {headerInfo.breadcrumbs ? (
              <div className="tw-flex tw-items-center tw-overflow-x-auto">
                {headerInfo.breadcrumbs.map((crumb, index) => (
                  <React.Fragment key={index}>
                    {index > 0 && crumb.title && (
                      <ArrowLeft className="tw-ml-4 tw-mr-4 tw-flex-shrink-0" />
                    )}
                    {index === headerInfo.breadcrumbs.length - 1 ? (
                      <span className="tw-text-xl tw-font-bold tw-whitespace-nowrap">
                        {crumb.title}
                      </span>
                    ) : (
                      <h1 className="tw-text-xl tw-font-bold tw-whitespace-nowrap">
                        {crumb.title}
                      </h1>
                    )}
                  </React.Fragment>
                ))}
              </div>
            ) : (
              <h1 className="tw-text-xl tw-font-bold tw-truncate">
                {headerInfo.title}
              </h1>
            )}
            {headerInfo.showSettingsIcon && (
              <Button
                variant="secondary"
                size="icon"
                className="tw-ml-3 tw-rounded-3xl tw-flex-shrink-0"
                onClick={handleSettingsClick}
              >
                <Settings size={24} />
              </Button>
            )}
          </header>
        )}
        <main className="tw-flex-1 tw-overflow-y-auto tw-pb-16">
          {children}
        </main>
        {shouldShowBottomNav() && (
          <BottomNav profilePicture={user?.profilePicture} />
        )}
      </div>
    </div>
  );
});

export default Layout;
