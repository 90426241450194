import { observer } from "mobx-react-lite";
import { FC, useState } from "react";
import { Input } from "../../shadcn-components/input";
import { Button } from "../../shadcn-components/button";
import { Label } from "../../shadcn-components/label";
import { useForm } from "react-hook-form";
import signUpStore, {
  formSchemaNew,
  FormValuesUpdated,
} from "../../stores/signup-store";
import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate } from "react-router-dom";
import Cropper from "react-easy-crop";
import { X, Check, Image as ImageIcon } from "lucide-react";
import { authStore } from "../../stores/auth-store";
import { LoadingSpinner } from "../../shadcn-components/loading-spinner";
interface Area {
  x: number;
  y: number;
  width: number;
  height: number;
}

interface CropData {
  croppedArea: Area;
  croppedAreaPixels: Area;
}

export const ProfileForm: FC = observer(() => {
  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields, isSubmitted },
    watch,
    setValue,
    getValues,
    trigger,
  } = useForm<FormValuesUpdated>({
    resolver: zodResolver(formSchemaNew),
    defaultValues: {
      accountName: "",
      email: "",
      profilePicture: undefined,
      gender: "",
    },
    mode: "onTouched",
    reValidateMode: "onChange",
  });
  const [profileImage, setProfileImage] = useState<string | null>(null);
  const [profileCrop, setProfileCrop] = useState({ x: 0, y: 0 });
  const [profileZoom, setProfileZoom] = useState(1);
  const [profileCompletedCrop, setProfileCompletedCrop] =
    useState<CropData | null>(null);
  const [profilePreview, setProfilePreview] = useState<string | null>(null);
  const { isSubmittingProfile } = signUpStore;
  const selectedGender = watch("gender");

  const handleProfileUpload = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.onchange = (e) => {
      const file = (e.target as HTMLInputElement).files?.[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          setProfileImage(reader.result as string);
          setProfileCrop({ x: 0, y: 0 });
          setProfileZoom(1);
        };
        reader.readAsDataURL(file);
      }
    };
    input.click();
  };

  const onProfileCropComplete = (
    croppedArea: Area,
    croppedAreaPixels: Area
  ) => {
    setProfileCompletedCrop({ croppedArea, croppedAreaPixels });
  };

  const handleProfileConfirm = async () => {
    if (!profileCompletedCrop || !profileImage) {
      return;
    }

    try {
      const canvas = document.createElement("canvas");
      const image = new Image();
      image.src = profileImage;

      await new Promise((resolve) => {
        image.onload = () => {
          resolve(null);
        };
      });

      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;

      canvas.width = profileCompletedCrop.croppedAreaPixels.width;
      canvas.height = profileCompletedCrop.croppedAreaPixels.height;

      const ctx = canvas.getContext("2d");
      if (!ctx) {
        console.error("Failed to get canvas context");
        return;
      }

      ctx.drawImage(
        image,
        profileCompletedCrop.croppedAreaPixels.x * scaleX,
        profileCompletedCrop.croppedAreaPixels.y * scaleY,
        profileCompletedCrop.croppedAreaPixels.width * scaleX,
        profileCompletedCrop.croppedAreaPixels.height * scaleY,
        0,
        0,
        profileCompletedCrop.croppedAreaPixels.width,
        profileCompletedCrop.croppedAreaPixels.height
      );

      const blob = await new Promise<Blob>((resolve) =>
        canvas.toBlob((blob) => resolve(blob!), "image/jpeg", 0.95)
      );

      const fileName = `profile-${Date.now()}.jpg`;
      const croppedFile = new File([blob], fileName, { type: "image/jpeg" });

      setValue("profilePicture", croppedFile);
      const previewUrl = URL.createObjectURL(croppedFile);

      setProfilePreview(previewUrl);
      setProfileImage(null);
      setProfileCompletedCrop(null);
      setProfileCrop({ x: 0, y: 0 });
      setProfileZoom(1);
    } catch (e) {
      console.error("Error in profile confirmation:", e);
    }
  };
  const handleProfileCancel = () => {
    setProfileImage(null);
    setProfilePreview(null);
    setProfileCompletedCrop(null);
    setProfileCrop({ x: 0, y: 0 });
    setProfileZoom(1);
    setValue("profilePicture", undefined);
  };

  const onSubmit = async (data: FormValuesUpdated) => {
    const isValid = await trigger();
    if (!isValid) return;
    const success = await signUpStore.submitProfile(data);
    if (success) {
      await authStore.refreshAuth();
      navigate("/explore");
    }
  };

  const shouldShowError = (fieldName: keyof FormValuesUpdated) => {
    return (touchedFields[fieldName] || isSubmitted) && errors[fieldName];
  };
  const navigate = useNavigate();

  return (
    <div className="tw-min-h-screen tw-p-4">
      {isSubmittingProfile ? (
        <LoadingSpinner />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="tw-flex tw-flex-col tw-items-center tw-mb-6">
            {profileImage ? (
              <div className="tw-flex tw-flex-col tw-items-center">
                <div className="tw-relative tw-aspect-square tw-w-32 tw-h-32 tw-overflow-hidden tw-rounded-full">
                  <Cropper
                    image={profileImage}
                    crop={profileCrop}
                    zoom={profileZoom}
                    aspect={1}
                    onCropChange={setProfileCrop}
                    onZoomChange={setProfileZoom}
                    onCropComplete={onProfileCropComplete}
                    cropShape="round"
                  />
                </div>
                <div className="tw-mt-4 tw-flex tw-gap-2">
                  <Button
                    variant="outline"
                    size="icon"
                    onClick={handleProfileCancel}
                    className="tw-bg-white"
                    type="button"
                  >
                    <X className="tw-h-4 tw-w-4" />
                  </Button>
                  <Button
                    variant="default"
                    size="icon"
                    onClick={handleProfileConfirm}
                    className="tw-bg-indigo-500 hover:tw-bg-indigo-600"
                    type="button"
                  >
                    <Check className="tw-h-4 tw-w-4" />
                  </Button>
                </div>
              </div>
            ) : (
              <div className="tw-flex tw-flex-col tw-items-center">
                <div className="tw-w-32 tw-h-32 tw-rounded-full tw-bg-gray-100 tw-flex tw-items-center tw-justify-center">
                  {profilePreview ? (
                    <img
                      src={profilePreview}
                      alt="Profile"
                      className="tw-w-full tw-h-full tw-rounded-full tw-object-cover"
                    />
                  ) : (
                    <span className="tw-text-gray-500 tw-text-2xl">
                      {watch("accountName")?.[0]?.toUpperCase() || ""}
                    </span>
                  )}
                </div>
                <Button
                  type="button"
                  variant="outline"
                  className="tw-mt-4 tw-text-[#1E28FF] !tw-border-[#1E28FF] !tw-rounded-[38px]"
                  onClick={handleProfileUpload}
                >
                  <ImageIcon className="tw-w-4 tw-h-4 tw-mr-2" />
                  Add Profile Photo
                </Button>
              </div>
            )}
          </div>

          {/* Form Fields */}
          <div className="tw-space-y-6">
            <div className="tw-space-y-2">
              <Label htmlFor="name" className="tw-block tw-text-left">
                Name <span className="tw-text-red-500">*</span>
              </Label>
              <Input
                id="name"
                className="tw-w-full !tw-rounded-[38px]"
                {...register("accountName", {
                  onChange: () => {
                    if (touchedFields.accountName) {
                      trigger("accountName");
                    }
                  },
                })}
                aria-invalid={shouldShowError("accountName") ? "true" : "false"}
              />
              {shouldShowError("accountName") && (
                <p className="tw-text-red-500 tw-text-sm">
                  {errors.accountName?.message}
                </p>
              )}
            </div>

            <div className="tw-space-y-2">
              <Label htmlFor="email" className="tw-block tw-text-left">
                Email
              </Label>
              <Input
                id="email"
                type="email"
                className="tw-w-full !tw-rounded-[38px]"
                {...register("email")}
                aria-invalid={errors.email ? "true" : "false"}
              />
              {touchedFields.email && errors.email && (
                <p className="tw-text-red-500 tw-text-sm">
                  {errors.email.message}
                </p>
              )}
            </div>

            <div className="tw-space-y-2">
              <Label className="tw-block tw-text-left">
                Gender <span className="tw-text-red-500">*</span>
              </Label>
              <div className="tw-flex tw-gap-2">
                {["Woman", "Man", "Others"].map((gender) => (
                  <Button
                    key={gender}
                    type="button"
                    variant="outline"
                    className={`
                    tw-flex-1 tw-h-10 !tw-rounded-[38px] tw-border tw-transition-all
                    ${
                      selectedGender === gender
                        ? "!tw-border-[#1E28FF] tw-bg-[#1E28FF]/10 !tw-text-[#1E28FF]"
                        : "tw-border-[#131315]/10 tw-text-gray-600 tw-hover:tw-bg-gray-50"
                    }
                  `}
                    onClick={() =>
                      setValue("gender", gender, { shouldValidate: false })
                    }
                  >
                    {gender}
                  </Button>
                ))}
              </div>
              {touchedFields.gender && errors.gender && (
                <p className="tw-text-red-500 tw-text-sm">
                  {errors.gender.message}
                </p>
              )}
            </div>
          </div>

          {signUpStore.profileSubmitError && (
            <p className="tw-text-red-500 tw-text-sm tw-mt-4">
              {signUpStore.profileSubmitError}
            </p>
          )}

          <Button
            type="submit"
            className="tw-w-full tw-mt-8"
            disabled={signUpStore.isSubmittingProfile}
          >
            {signUpStore.isSubmittingProfile ? "Submitting..." : "Submit"}
          </Button>
        </form>
      )}
    </div>
  );
});
