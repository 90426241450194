import { FC } from "react";
import { Card } from "../../shadcn-components/card";
import { OptionItem } from "../../stores/signup-store";
import { allInterests } from "../../utils/interests-utils";
import { observer } from "mobx-react-lite";

interface InterestCardProps {
  options: OptionItem[];
}

export const InterestCards: FC<InterestCardProps> = observer(({ options }) => {
  return (
    <div className="tw-flex tw-gap-4 tw-overflow-x-auto tw-scrollbar-hide">
      {options.map((interest) => (
        <Card
          key={interest.id}
          className="tw-w-[114px] tw-min-w-[114px] tw-h-[70px] !tw-bg-[#F1F5F9] tw-border-custom-border"
        >
          <div className="tw-h-full tw-w-full tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-1 tw-p-2">
            <span className="tw-text-2xl">
              {
                allInterests.filter((i) => i.name === interest.display_en)?.[0]
                  ?.emoji
              }
            </span>
            <span className="tw-text-xs tw-font-semibold">
              {interest.display_en}
            </span>
          </div>
        </Card>
      ))}
    </div>
  );
});
