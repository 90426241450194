import * as z from "zod";

export const manageCommunitySchema = z.object({
  title: z.string().min(1, "Community name is required").max(100),
  handle: z.string().min(1, "Community username is required").max(20),
  description: z.string().max(1000).optional(),
  location: z.string().optional(),
  categories: z.array(z.string()).optional(),
  // Handle both File uploads and existing image URLs
  coverPhoto: z.union([z.instanceof(File), z.string(), z.null()]),
  profilePhoto: z.union([z.instanceof(File), z.string(), z.null()]),
  socialMedia: z
    .object({
      instagram: z.string().optional(),
      whatsapp: z.string().optional(),
      youtube: z.string().optional(),
      twitter: z.string().optional(),
      website: z.string().optional(),
    })
    .optional(),
});

export type ManageCommunityFormValues = z.infer<typeof manageCommunitySchema>;

// Helper type for form state
export interface ManageCommunityImageState {
  coverPhotoUrl: string | null;
  profilePhotoUrl: string | null;
  coverPhotoFile: File | null;
  profilePhotoFile: File | null;
}
