import { FollowerUser } from "../stores/community-store";

export const getUsersPictures = (communityId:string, followersByCommunityId: Map<string, FollowerUser[]>) => {
    const followerUserObjects = followersByCommunityId.get(communityId) || [];
    const followerUsers = followerUserObjects.map((obj) => {
      const user = {
        name: obj.accountName,
        profilePicture: obj.profilePicture,
        id: obj.userId,
      };
      return user;
    });
    return followerUsers;
};