import { Button } from "../../shadcn-components/button";
import jackpassText from "../../static/jackpass full logo white.svg";
import signupStore from "../../stores/signup-store";
import { FC, useEffect } from "react";
import { LandingPageCommunitiesSection } from "../communities/landing-page-communities";
import EventSection from "../events/components/landing-events-section";
import { DownloadCard } from "../download-app-card";
import { Footer } from "./landing-footer";
import AnimatedInfoCards from "../landing-cards";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { InterestCards } from "./interest-cards";
import { authStore } from "../../stores/auth-store";

export const Landing: FC = observer(() => {
  const { getAllClientSideData, interestOptions } = signupStore;
  const { getUser } = authStore;
  useEffect(() => {
    const fetch = async () => {
      await getAllClientSideData();
      // await getUser();
    };
    fetch();
  }, [getAllClientSideData, getUser]);

  const navigate = useNavigate();

  const grayDivider = (
    <div className="tw-h-2 tw-bg-gray-100 tw-w-full tw-mb-6" />
  );
  return (
    <div className="tw-w-full">
      <section className="tw-relative tw-h-[600px]">
        <div className="tw-absolute tw-inset-0">
          <video
            autoPlay
            muted
            loop
            playsInline
            className="tw-absolute tw-w-full tw-h-full tw-object-cover"
          >
            <source
              src="https://jackpass-video.s3.ap-south-1.amazonaws.com/jackpass-landing-video.mp4"
              type="video/mp4"
            />
          </video>
          <div className="tw-absolute tw-inset-0 tw-bg-black/50" />
        </div>
        <div className="tw-relative tw-h-full tw-z-10 tw-flex tw-flex-col">
          <div className="tw-flex tw-items-center tw-justify-between tw-p-4 sm:tw-p-4">
            <div className="tw-flex tw-items-center tw-gap-4">
              <h1 className="tw-text-base tw-text-white">Delhi NCR</h1>
            </div>

            <Button
              variant="default"
              className="tw-text-sm sm:tw-text-base"
              onClick={() => navigate("/join")}
            >
              Sign up
            </Button>
          </div>

          <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-flex-grow tw-text-white tw-px-4">
            <div className="tw-flex tw-items-center tw-justify-center tw-mb-6 sm:tw-mb-8">
              <img
                src={jackpassText}
                alt="Jackpass"
                className="tw-h-8 sm:tw-h-10 tw-text-white"
              />
            </div>
            <h1 className="tw-text-3xl sm:tw-text-4xl md:tw-text-6xl tw-font-bold tw-text-center">
              Build your tribe
            </h1>
          </div>
        </div>
      </section>

      <section className="tw-min-h-screen">
        <div className="tw-w-full tw-px-3 tw-py-5 sm:tw-px-4 sm:tw-py-5">
          <AnimatedInfoCards />
        </div>

        <div className="tw-ml-4 tw-my-6">
          <h1 className="tw-font-bold tw-text-base sm:tw-text-lg tw-mb-1 tw-text-left">
            Pick your interest
          </h1>
          <div className="tw-mt-4 sm:tw-mt-8">
            <InterestCards options={interestOptions} />
          </div>
        </div>
        {grayDivider}
        <LandingPageCommunitiesSection />
        {grayDivider}
        <EventSection />
        <DownloadCard />
      </section>
      <Footer />
    </div>
  );
});
