import { observer } from "mobx-react-lite";
import { Button } from "../../shadcn-components/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../shadcn-components/form";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useNavigate } from "react-router-dom";
import { getPhoneData, PhoneInput } from "../../shadcn-components/phone";
import signUpStore from "../../stores/signup-store";
import { useState } from "react";
import { LoadingSpinner } from "../../shadcn-components/loading-spinner";

const phoneSchema = z.object({
  phone: z.string().min(10, "Phone number must be at least 10 digits"),
});

type PhoneFormValues = z.infer<typeof phoneSchema>;

export const LoginScreen: React.FC = observer(() => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const form = useForm<PhoneFormValues>({
    resolver: zodResolver(phoneSchema),
    defaultValues: {
      phone: "",
    },
  });

  const isPhoneValid = (phone: string) => {
    const phoneData = getPhoneData(phone);
    if (!phoneData.isValid) {
      console.log("phone invalid error");
      return;
    }
    return phoneData.isValid;
  };

  const onSubmit = async (data: PhoneFormValues) => {
    setIsLoading(true);
    try {
      if (!isPhoneValid(data.phone)) {
        form.setError("phone", {
          type: "manual",
          message: "Please enter a valid phone number",
        });
        setIsLoading(false);
        return;
      }

      await signUpStore.getOtp(data.phone);
      setIsLoading(false);
      navigate("/verify", { state: { phoneNumber: data.phone } });
    } catch (error) {
      console.error("Error:", error);
      setIsLoading(false);
      form.setError("phone", {
        type: "manual",
        message: "An error occurred. Please try again.",
      });
    }
  };

  return (
    <div className="tw-flex tw-flex-col tw-min-h-screen tw-bg-white">
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="tw-p-4">
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="tw-space-y-6"
            >
              <FormField
                control={form.control}
                name="phone"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="tw-text-sm tw-text-gray-600 tw-text-left">
                      Phone Number<span className="tw-text-red-500">*</span>
                    </FormLabel>
                    <FormControl>
                      <div className="tw-flex tw-items-center tw-px-3 tw-py-2">
                        <PhoneInput
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e);
                          }}
                          size={30}
                        />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <Button
                type="submit"
                className="tw-w-full tw-bg-blue-600 hover:tw-bg-blue-700 tw-text-white tw-py-6 !tw-rounded-[38px]"
              >
                Send OTP To Whatsapp
              </Button>
            </form>
          </Form>
        </div>
      )}
    </div>
  );
});
