import { FC } from "react";
import { BackButton } from "./back-button";

const PrivacyPolicy: FC = () => {
  return (
    <div className="tw-min-h-screen tw-bg-white">
      <div className="tw-container tw-mx-auto tw-p-4 sm:tw-p-8 tw-max-w-4xl">
        {/* Header with BackButton */}
        <div className="tw-flex tw-items-center tw-mb-6">
          <BackButton />
          <h1 className="tw-text-2xl tw-font-bold tw-text-left">
            Privacy Policy
          </h1>
        </div>

        <div className="tw-flex tw-justify-center">
          <ol className="tw-list-decimal tw-space-y-4 tw-ml-4 sm:tw-ml-0 tw-w-full sm:tw-max-w-2xl">
            <li className="tw-mb-4">
              <span className="tw-font-semibold tw-block tw-text-left">
                Data We Collect
              </span>
              <ul className="tw-list-disc tw-pl-4 tw-space-y-2">
                <li className="tw-text-left">
                  Personal Information: Name, email, phone number, payment
                  details, and location.
                </li>
                <li className="tw-text-left">
                  Community Activity: Attendance records, event interactions,
                  and preferences.
                </li>
                <li className="tw-text-left">
                  Device Information: IP address, browser type, and device
                  identifiers.
                </li>
              </ul>
            </li>

            <li className="tw-mb-4">
              <span className="tw-font-semibold tw-block tw-text-left">
                How We Use Your Data
              </span>
              <ul className="tw-list-disc tw-pl-4 tw-space-y-2">
                <li className="tw-text-left">
                  Facilitate account creation, community memberships, and event
                  registrations.
                </li>
                <li className="tw-text-left">
                  Process payments and provide receipts.
                </li>
                <li className="tw-text-left">
                  Improve platform functionality and personalize your
                  experience.
                </li>
              </ul>
            </li>

            <li className="tw-mb-4">
              <span className="tw-font-semibold tw-block tw-text-left">
                Data Sharing and Disclosure
              </span>
              <ul className="tw-list-disc tw-pl-4 tw-space-y-2">
                <li className="tw-text-left">
                  Shared with organizers for event operations and participant
                  management.
                </li>
                <li className="tw-text-left">
                  Not sold to third parties for marketing purposes.
                </li>
                <li className="tw-text-left">
                  Disclosed only when required by law or to protect platform
                  integrity.
                </li>
              </ul>
            </li>

            <li className="tw-mb-4">
              <span className="tw-font-semibold tw-block tw-text-left">
                Data Security
              </span>
              <ul className="tw-list-disc tw-pl-4 tw-space-y-2">
                <li className="tw-text-left">
                  We use industry-standard encryption to secure transactions and
                  personal data.
                </li>
                <li className="tw-text-left">
                  Regular audits are conducted to ensure compliance with data
                  protection laws.
                </li>
              </ul>
            </li>

            <li className="tw-mb-4">
              <span className="tw-font-semibold tw-block tw-text-left">
                User Rights
              </span>
              <ul className="tw-list-disc tw-pl-4 tw-space-y-2">
                <li className="tw-text-left">
                  Access and correct personal data via account settings.
                </li>
                <li className="tw-text-left">
                  Opt-out of marketing communications by updating preferences.
                </li>
                <li className="tw-text-left">
                  Request account deletion by contacting support@jackpass.com.
                </li>
              </ul>
            </li>

            <li className="tw-mb-4">
              <span className="tw-font-semibold tw-block tw-text-left">
                Cookies
              </span>
              <ul className="tw-list-disc tw-pl-4 tw-space-y-2">
                <li className="tw-text-left">
                  We use cookies to enhance your experience, analyze platform
                  usage, and remember your preferences.
                </li>
                <li className="tw-text-left">
                  You may disable cookies in your browser settings, but certain
                  features may be restricted.
                </li>
              </ul>
            </li>

            <li className="tw-mb-4">
              <span className="tw-font-semibold tw-block tw-text-left">
                Policy Updates
              </span>
              <ul className="tw-list-disc tw-pl-4 tw-space-y-2">
                <li className="tw-text-left">
                  This policy is subject to change. Significant updates will be
                  notified via email or platform announcements.
                </li>
              </ul>
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
