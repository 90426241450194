import { action, makeAutoObservable, observable, runInAction } from "mobx";
import { Community } from "./community-store";
import axios from "axios";
import { EventData } from "../components/events/stores/event-store";

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

class CalendarStore {
  @observable eventData: EventData[] = [];
  @observable adminCommunities: Community[] = [];
  @observable isAdminCommunitiesLoading = false;
  @observable followingCommunities: Community[] = [];
  @observable isFollowingCommunitiesLoading = false;
  @observable eventsOfUser: EventData[] = [];
  constructor() {
    makeAutoObservable(this);
  }

  @action
  getEvents = () => {};

  @action
  getAdminCommunities = async () => {
    this.isAdminCommunitiesLoading = true;
    const response =
      // await axios.get(`${apiBaseUrl}/communities?isAdmin=true`, {
      await axios.get(`${apiBaseUrl}/communities`, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      });
    if (response.status !== 200) console.log("Failed to get communities");
    runInAction(() => {
      this.adminCommunities = response.data.items;
      this.isAdminCommunitiesLoading = false;
    });
  };
  @action
  getFollowingCommunities = async () => {
    this.isFollowingCommunitiesLoading = true;
    const response = await axios.get(
      `${apiBaseUrl}/communities?isFollowing=true`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );
    if (response.status !== 200) console.log("Failed to get communities");
    runInAction(() => {
      this.followingCommunities = response.data.items;
      this.isFollowingCommunitiesLoading = false;
    });
  };

  @action
  getEventsOfUser = async () => {
    const params = {
      dateRangeStart: new Date(
        Date.now()
      ).toISOString(),
      dateRangeEnd: new Date(
        Date.now() + 7 * 24 * 60 * 60 * 1000
      ).toISOString(),
      city: "delhi_ncr",
    };
    const queryString = new URLSearchParams(params).toString();
    try {
      const response = await axios.get(`${apiBaseUrl}/events?${queryString}`, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      runInAction(() => {
        this.eventsOfUser = response.data.data;
      });
    } catch (error) {
      console.error("Failed to fetch events:", error);
      runInAction(() => {
        this.eventsOfUser = [];
      });
    }
  };
}

const calendarStore = new CalendarStore();
export default calendarStore;
