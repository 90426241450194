import { FC, useEffect, useState, useRef } from "react";
import { Input } from "../shadcn-components/input";
import { Check, Loader2 } from "lucide-react";
import { observer } from "mobx-react-lite";
import signUpStore from "../stores/signup-store";
import { useDebouncedCallback } from "use-debounce";
import { communityStore } from "../stores/community-store";

interface SignUpUsernameFieldProps {
  value: string;
  onChange: (value: string) => void;
  className?: string;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onCheckingChange?: (isChecking: boolean) => void;
  error?: boolean;
  customStore?: {
    setIsUsernameAvailable: (available: boolean | null) => void;
    setUsernameError: (error: string | null) => void;
    isUsernameAvailable: boolean | null;
    usernameError: string | null;
  };
  skipInitialValidation?: boolean;
}

export const SignUpUsernameField: FC<SignUpUsernameFieldProps> = observer(
  ({
    value,
    onChange,
    className,
    onKeyDown,
    onCheckingChange,
    error,
    customStore,
    skipInitialValidation,
  }) => {
    const [isChecking, setIsChecking] = useState(false);
    const initialValue = useRef("");
    const { isUserHandleAvailable } = signUpStore;
    const store = customStore || communityStore;
    const hasSetInitialValue = useRef(false);

    useEffect(() => {
      if (value && !hasSetInitialValue.current) {
        initialValue.current = value;
        hasSetInitialValue.current = true;
      }
    }, [value]); 

    const debouncedCheckUsername = useDebouncedCallback(
      async (username: string) => {
        if (skipInitialValidation && username === initialValue.current) {
          return;
        }

        if (username.length > 0) {
          // Check for whitespace first
          if (/\s/.test(username)) {
            store.setIsUsernameAvailable(false);
            store.setUsernameError(
              "Username cannot contain spaces or whitespace characters"
            );
            setIsChecking(false);
            onCheckingChange?.(false);
            return;
          }
          setIsChecking(true);
          onCheckingChange?.(true);
          try {
            const available = await isUserHandleAvailable(username);
            store.setIsUsernameAvailable(available);
            if (!available) {
              store.setUsernameError("Username taken. Please try another");
            } else {
              store.setUsernameError(null);
            }
          } catch (error) {
            console.error("Error checking username availability:", error);
            store.setIsUsernameAvailable(null);
            store.setUsernameError("Error checking username availability");
          } finally {
            setIsChecking(false);
            onCheckingChange?.(false);
          }
        } else {
          store.setIsUsernameAvailable(null);
          store.setUsernameError(null);
          setIsChecking(false);
          onCheckingChange?.(false);
        }
      },
      500
    );

    useEffect(() => {
      debouncedCheckUsername(value);
      return () => {
        debouncedCheckUsername.cancel();
      };
    }, [value, debouncedCheckUsername]);

    const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.value);
    };

    return (
      <div className="tw-space-y-2">
        <div className="tw-relative">
          <Input
            type="text"
            placeholder="@username"
            value={value}
            onChange={handleUsernameChange}
            className={`tw-pr-10 ${className} ${
              error ? "tw-border-red-500" : ""
            }`}
            onKeyDown={onKeyDown}
          />
          <div className="tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-3">
            {isChecking && (
              <Loader2 className="tw-h-5 tw-w-5 tw-text-gray-400 tw-animate-spin" />
            )}
            {!isChecking && store.isUsernameAvailable === true && (
              <div className="tw-relative tw-inline-flex tw-items-center tw-justify-center tw-w-5 tw-h-5 tw-rounded-full tw-bg-primary">
                <Check className="tw-text-white" size={16} />
              </div>
            )}
          </div>
        </div>
        <div className="tw-flex tw-items-center tw-justify-center tw-text-sm text-gray-500">
          <span>
            {isChecking && "Checking..."}
            {!isChecking && !value && "Enter a username"}
            {!isChecking && store.usernameError && (
              <span className="tw-text-red-500">{store.usernameError}</span>
            )}
          </span>
        </div>
      </div>
    );
  }
);
