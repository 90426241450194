import { FC } from "react";
import { BackButton } from "./back-button";

const TermsAndConditions: FC = () => {
  return (
    <div className="tw-min-h-screen tw-bg-white">
      <div className="tw-container tw-mx-auto tw-p-4 sm:tw-p-8 tw-max-w-4xl">
        <div className="tw-flex tw-items-center tw-mb-6">
          <BackButton />
          <h1 className="tw-text-2xl tw-font-bold tw-text-left">Terms and Conditions</h1>
        </div>

        <ol className="tw-list-decimal tw-space-y-4 tw-ml-4">
          <li className="tw-mb-4">
            <span className="tw-font-semibold tw-block tw-text-left">
              Introduction
            </span>
            <p className="tw-text-left tw-mx-2">
              Welcome to Jackpass! By accessing or using our website, mobile
              app, or services, you agree to these Terms & Conditions. If you
              disagree with any of these terms, you should cease using the
              platform immediately. These terms are subject to periodic updates,
              and continued usage constitutes agreement to the revised terms.
            </p>
          </li>

          <li className="tw-mb-4">
            <span className="tw-font-semibold tw-block tw-text-left">
              Eligibility
            </span>
            <ul className="tw-list-disc tw-pl-4 tw-space-y-2">
              <li className="tw-text-left">
                You should be at least 18 years old to create an account.
              </li>
              <li className="tw-text-left">
                Users under 18 may join communities or attend events with
                parental or guardian supervision.
              </li>
              <li className="tw-text-left">
                We reserve the right to suspend or terminate accounts found in
                violation of this policy.
              </li>
            </ul>
          </li>

          <li className="tw-mb-4">
            <span className="tw-font-semibold tw-block tw-text-left">
              User Responsibilities
            </span>
            <ul className="tw-list-disc tw-pl-4 tw-space-y-2">
              <li className="tw-text-left">
                Provide accurate, current, and complete information during
                registration.
              </li>
              <li className="tw-text-left">
                Maintain confidentiality of your account credentials.
              </li>
              <li className="tw-text-left">
                Use Jackpass services solely for lawful purposes.
              </li>
              <li className="tw-text-left">
                Avoid any activity that disrupts the platform or compromises
                user data.
              </li>
            </ul>
          </li>

          <li className="tw-mb-4">
            <span className="tw-font-semibold tw-block tw-text-left">
              Organizer-Specific Terms
            </span>
            <ul className="tw-list-disc tw-pl-4 tw-space-y-2">
              <li className="tw-text-left">
                Organizers must ensure compliance with local regulations when
                hosting events.
              </li>
              <li className="tw-text-left">
                Event descriptions, pricing, and policies must be transparent
                and accurate.
              </li>
              <li className="tw-text-left">
                Organizers are solely responsible for cancellations, refunds, or
                disputes.
              </li>
            </ul>
          </li>

          <li className="tw-mb-4">
            <span className="tw-font-semibold tw-block tw-text-left">
              Payments and Refunds
            </span>
            <ul className="tw-list-disc tw-pl-4 tw-space-y-2">
              <li className="tw-text-left">
                Payments for events and subscriptions are processed securely
                through UPI.
              </li>
              <li className="tw-text-left">
                Refund requests must be directed to organizers. Jackpass does
                not directly process refunds unless explicitly stated.
              </li>
              <li className="tw-text-left">
                Any taxes, fees, or additional charges are the sole
                responsibility of the organizers.
              </li>
            </ul>
          </li>

          <li className="tw-mb-4">
            <span className="tw-font-semibold tw-block tw-text-left">
              Prohibited Activities
            </span>
            <ul className="tw-list-disc tw-pl-4 tw-space-y-2">
              <li className="tw-text-left">
                Impersonating another user, organizer, or community.
              </li>
              <li className="tw-text-left">
                Posting harmful, defamatory, or misleading content.
              </li>
              <li className="tw-text-left">
                Attempting to exploit vulnerabilities in our platform for
                unauthorized access.
              </li>
            </ul>
          </li>

          <li className="tw-mb-4">
            <span className="tw-font-semibold tw-block tw-text-left">
              Intellectual Property
            </span>
            <ul className="tw-list-disc tw-pl-4 tw-space-y-2">
              <li className="tw-text-left">
                All content, including logos, trademarks, and text on Jackpass,
                is protected under copyright laws.
              </li>
              <li className="tw-text-left">
                Users retain ownership of content they upload but grant Jackpass
                a non-exclusive license to use it for promotional purposes.
              </li>
            </ul>
          </li>

          <li className="tw-mb-4">
            <span className="tw-font-semibold tw-block tw-text-left">
              Dispute Resolution
            </span>
            <ul className="tw-list-disc tw-pl-4 tw-space-y-2">
              <li className="tw-text-left">
                Any disputes will be resolved under the jurisdiction of Indore,
                India.
              </li>
              <li className="tw-text-left">
                Jackpass reserves the right to mediate disputes between
                organizers and participants, but it is not legally liable for
                event outcomes.
              </li>
            </ul>
          </li>

          <li className="tw-mb-4">
            <span className="tw-font-semibold tw-block tw-text-left">
              Termination
            </span>
            <ul className="tw-list-disc tw-pl-4 tw-space-y-2">
              <li className="tw-text-left">
                Jackpass may suspend or terminate your account for violations of
                these terms.
              </li>
              <li className="tw-text-left">
                Users may terminate their accounts by contacting
                support@jackpass.com.
              </li>
            </ul>
          </li>

          <li className="tw-mb-4">
            <span className="tw-font-semibold tw-block tw-text-left">
              Governing Law
            </span>
            <ul className="tw-list-disc tw-pl-4 tw-space-y-2">
              <li className="tw-text-left">
                These terms are governed by the laws of India and are subject to
                jurisdictional oversight.
              </li>
            </ul>
          </li>
        </ol>
      </div>
    </div>
  );
};
export default TermsAndConditions;
