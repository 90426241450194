import { Card, CardContent } from "../../../shadcn-components/card";
import { Button } from "../../../shadcn-components/button";
import bhagBanner from "../../../static/bhag-banner.jpg";
import { EventData, eventStore } from "../stores/event-store";
import { FC, useEffect } from "react";
import { LoadingSpinner } from "../../../shadcn-components/loading-spinner";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { exploreStore } from "../../../stores/explore-store";
import { getEventImage } from "../../../utils/image-utils";

const EventCard = ({ event }: { event: EventData }) => {
  const navigate = useNavigate();
  const formatDate = (dateStr: string) => {
    const date = new Date(dateStr);
    return date.toLocaleString("en-US", {
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    });
  };

  return (
    <Card
      className="tw-w-[200px] tw-flex-shrink-0 tw-overflow-hidden tw-border-0 !tw-shadow-none"
      onClick={() => navigate(`/e/${event.eventId}`)}
    >
      <div className="tw-relative tw-mb-3">
        <div className="tw-w-[200px] tw-h-[250px]">
          {event.logo ? (
            <img
              src={getEventImage(event.logo)}
              alt={event.name}
              className="tw-w-full tw-h-full tw-object-cover !tw-rounded-lg"
            />
          ) : (
            <div className="tw-h-[203px] tw-aspect-[4/3] tw-bg-gray-200 tw-mb-3 tw-flex tw-items-center tw-justify-center">
              <span className="tw-text-gray-400 tw-text-lg">No Image</span>
            </div>
          )}
        </div>
        {event.price > 0 && (
          <div className="tw-absolute tw-top-3 tw-right-3 tw-bg-white tw-rounded-full tw-px-3 tw-py-1 tw-text-xs">
            ₹{event.price}/person
          </div>
        )}
        {event.price === 0 && (
          <div className="tw-absolute tw-top-3 tw-right-3 tw-bg-white tw-rounded-full tw-px-3 tw-py-1 tw-text-xs">
            Free
          </div>
        )}
      </div>
      <CardContent className="!tw-p-1 tw-pt-3">
        <div className="tw-flex tw-items-center tw-gap-2 tw-mb-2">
          <div className="tw-w-6 tw-h-6 tw-rounded-full tw-overflow-hidden">
            <img
              src={bhagBanner}
              alt={event.communityDetails.title}
              className="tw-w-full tw-h-full tw-object-cover"
            />
          </div>
          <span className="tw-text-xs tw-font-semibold">
            By {event.communityDetails.title}
          </span>
        </div>

        <h3 className="tw-font-semibold tw-mb-1 tw-text-left tw-break-words">
          {event.name}
        </h3>

        <div className="tw-flex tw-flex-col tw-gap-1">
          <div className="tw-flex tw-items-start tw-gap-1">
            <span className="tw-text-sm">🕒</span>
            <span className="tw-text-sm tw-font-medium">
              {formatDate(event.startDateTime)}
            </span>
          </div>
          <div className="tw-flex tw-items-start tw-gap-1">
            <span className="tw-text-sm tw-ml-1">📍</span>
            <span className="tw-text-sm tw-break-words tw-font-medium">
              {event.location.primaryAddress}
            </span>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

const EventSection: FC = observer(() => {
  const { landingPageEvents: exploreEvents, isLoadingExploreEvents } =
    eventStore;
  const { selectEventsTab } = exploreStore;

  const navigate = useNavigate();
  useEffect(() => {
    eventStore.getFilteredEvents();
  }, []);
  const gridClass =
    exploreEvents.length > 1
      ? "tw-grid tw-grid-rows-2 tw-auto-cols-max tw-grid-flow-col tw-gap-4"
      : "tw-flex tw-gap-4";

  return (
    <section className="tw-px-4 tw-pb-3">
      <div className="tw-flex tw-justify-between tw-items-center tw-mb-4">
        <h2 className="tw-text-base tw-font-bold">Popular Events</h2>
        <Button
          variant="ghost"
          className="tw-text-primary tw-flex tw-items-center !tw-p-0"
          onClick={() => {
            selectEventsTab();
            navigate("/explore");
          }}
        >
          View All
        </Button>
      </div>

      <div className="tw-overflow-x-auto tw--mx-4 tw-px-4">
        {isLoadingExploreEvents ? (
          <div className="tw-flex tw-justify-center tw-py-8">
            <LoadingSpinner />
          </div>
        ) : (
          <div className={gridClass}>
            {exploreEvents.map((event) => (
              <EventCard key={event.eventId} event={event} />
            ))}
          </div>
        )}
      </div>
    </section>
  );
});

export default EventSection;
