import { action, makeAutoObservable, observable } from "mobx";
import { Community } from "./community-store";
import axios from "axios";

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

class SearchStore {
    @observable searchResults: Community[] = [];
  @observable isSearching: boolean = false;
  @observable searchQuery: string = "";

  constructor() {
    makeAutoObservable(this);
  }

  @action
  setSearchQuery(query: string) {
    this.searchQuery = query;
  }

  @action
  searchCommunities = async (query: string) => {
    try {
      this.isSearching = true;
      this.setSearchQuery(query);

      // Replace this with your actual API call
      const response = await axios.get(
        `${apiBaseUrl}/communities/?title=${encodeURIComponent(query)}`
      );

      this.searchResults = response.data.items;
    } catch (error) {
      console.error("Error searching communities:", error);
      this.searchResults = [];
    } finally {
      this.isSearching = false;
    }
  }

  @action
  clearSearch() {
    this.searchResults = [];
    this.searchQuery = "";
  }
}

const searchStore = new SearchStore();
export default searchStore;
