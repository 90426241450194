import { useState } from "react";
import { ImageCropper, ImageUploader } from "./image-uploader";

interface ImageUploadContainerProps {
  onImageChange: (file: File, previewUrl: string) => void;
  aspectRatio: number;
  previewUrl?: string | null;
  cropShape?: "rect" | "round";
  className?: string;
  buttonText?: string;
  height?: number;
}

export const ImageUploadContainer: React.FC<ImageUploadContainerProps> = ({
  onImageChange,
  aspectRatio,
  previewUrl,
  cropShape = "rect",
  className = "",
  buttonText,
  height,
}) => {
  const [tempImage, setTempImage] = useState<string | null>(null);

  const handleImageSelect = (file: File) => {
    const reader = new FileReader();
    reader.onload = () => {
      setTempImage(reader.result as string);
    };
    reader.readAsDataURL(file);
  };

  const handleCropConfirm = (croppedFile: File) => {
    if (tempImage && tempImage.startsWith("blob:")) {
      URL.revokeObjectURL(tempImage);
    }

    const newPreviewUrl = URL.createObjectURL(croppedFile);
    onImageChange(croppedFile, newPreviewUrl);
    setTempImage(null);
  };

  const handleCropCancel = () => {
    if (tempImage && tempImage.startsWith("blob:")) {
      URL.revokeObjectURL(tempImage);
    }
    setTempImage(null);
  };

  if (tempImage) {
    return (
      <ImageCropper
        image={tempImage}
        aspectRatio={aspectRatio}
        onConfirm={handleCropConfirm}
        onCancel={handleCropCancel}
        containerClassName={className}
        height={height}
      />
    );
  }

  return (
    <ImageUploader
      onImageSelect={handleImageSelect}
      previewUrl={previewUrl}
      className={className}
      buttonText={buttonText}
      height={height}
    />
  );
};
