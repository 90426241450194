import { motion, useAnimation } from "framer-motion";
import { useEffect, useState } from "react";
import bannerBike from "../static/banner-bikers.avif";
import bannerRunning from "../static/banner-running.jpg";
import bookClub from "../static/book-club.jpg";

const Banner = ({ speed = 5000 }: { speed?: number }) => {
  const itemWidth = 1000;
  const controls = useAnimation();
  const [key, setKey] = useState(0);
  const images = [
    {
      image: bannerRunning,
      text: `Rahul met Sunidhi, his now-wife, at a run club. "I joined a coffee run with a group, 
        and met her on the very first day."`,
    },
    {
      image: bookClub,
      text: "Radhika created her own book club. “I was tired of Netflix binges after work. I wanted a cozy meetup at my favorite café, and now I look forward to it every day.",
    },
    {
      image: bannerBike,
      text: `Ankit achieved his dream of a bike backpacking adventure in Ladakh. 
        "I was new in town, with a new bike, and needed some adventurous souls to join me."`,
    },
  ];

  // Effect to set initial position after component has mounted
  useEffect(() => {
    controls.set({ x: 0 });
  }, [controls]);

  useEffect(() => {
    const animate = async () => {
      await controls.start({
        x: -itemWidth * images.length,
        transition: {
          duration: (images.length * itemWidth) / speed,
          ease: "linear",
        },
      });
      setKey((prev) => prev + 1);
    };

    animate();
  }, [controls, itemWidth, speed, images.length, key]);

  // Effect to reset animation when key changes
  useEffect(() => {
    if (key > 0) {
      controls.set({ x: 0 });
    }
  }, [key, controls]);

  return (
    <div className="tw-overflow-hidden tw-w-full tw-max-w-[1000px] tw-mx-auto">
      <motion.div
        key={key}
        className="tw-flex"
        animate={controls}
        style={{ width: `${images.length * itemWidth * 2}px` }}
      >
        {[...images, ...images].map((item, index) => (
          <div
            key={index}
            className="tw-flex-none tw-w-[1000px] tw-h-[326px] tw-flex tw-flex-row tw-items-center tw-justify-between tw-px-8"
          >
            <div className="tw-w-[440px] tw-h-[247px] tw-overflow-hidden tw-rounded-lg">
              <img
                src={item.image}
                alt={`Item ${index}`}
                className="tw-w-full tw-h-full tw-object-cover"
              />
            </div>
            <div className="tw-w-[440px] tw-h-[247px] tw-flex tw-items-center">
              <p className="tw-text-2xl tw-font-bold tw-text-left">
                {item.text}
              </p>
            </div>
          </div>
        ))}
      </motion.div>
    </div>
  );
};

export default Banner;
