import axios from "axios";
import { action, makeAutoObservable, observable, runInAction } from "mobx";

interface ProfileData {
  userId: string;
  accountName: string;
  dateOfBirth: string;
  phoneNumber: string;
  cities?: string[];
  bio?: string;
  interests: string[];
}

type UpdateFieldKey = keyof Omit<ProfileData, "userId">;
const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

export interface UpdateStatus {
  field: UpdateFieldKey;
  status: "saving" | "success" | "error" | "verifying";
  message?: string;
}
class ProfileSettingsStore {
  @observable isSettingsOpen = false;
  @observable isLoading = false;
  @observable editProfileData: ProfileData | null = null;
  @observable updateStatus: UpdateStatus | null | undefined;

  constructor() {
    makeAutoObservable(this);
  }

  @action
  updateField = async (
    field: UpdateFieldKey,
    value: string | Date | string[]
  ) => {
    if (!this.editProfileData?.userId) return;

    const initialStatus = field === "phoneNumber" ? "verifying" : "saving";

    this.updateStatus = {
      field,
      status: initialStatus,
    };

    try {
      const updateData: Partial<ProfileData> = {};

      if (field === "dateOfBirth" && value instanceof Date) {
        updateData[field] = value.toISOString().split("T")[0];
      } else if (field === "cities") {
        updateData[field] = [value as string];
      } else if (field === "interests") {
        updateData[field] = value as [];
      } else {
        updateData[field] = value as string;
      }

      const response = await axios.post(`${apiBaseUrl}/users`, updateData, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      runInAction(() => {
        if (response.data.status === "success") {
          this.editProfileData = {
            ...this.editProfileData!,
            ...updateData,
          };

          this.updateStatus = {
            field,
            status: "success",
            message: "Successfully updated",
          };

          // Only set timeout for non-phone updates or after phone verification complete
          if (
            field !== "phoneNumber" ||
            this.updateStatus.status !== "verifying"
          ) {
            setTimeout(() => {
              runInAction(() => {
                this.updateStatus = null;
              });
            }, 2000);
          }
        }
      });
    } catch (error: any) {
      runInAction(() => {
        this.updateStatus = {
          field,
          status: "error",
          message: error.response?.data?.message || "Update failed",
        };
      });

      // Clear error status after 3 seconds
      setTimeout(() => {
        runInAction(() => {
          this.updateStatus = null;
        });
      }, 3000);
    }
  };

  @action
  clearUpdateStatus() {
    this.updateStatus = null;
  }

  @action
  getEditProfileData = async () => {
    this.isLoading = true;
    try {
      const response = await axios.get(`${apiBaseUrl}/users`, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true
      });
      runInAction(() => {
        this.editProfileData = response.data;
      });
    } catch (error) {
      console.error("Error fetching profile data:", error);
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  @action
  deleteUser = async () => {
    try {
      const response = await axios.delete(`${apiBaseUrl}/users`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  };

  @action
  toggleSettings() {
    this.isSettingsOpen = !this.isSettingsOpen;
  }

  @action
  openSettings() {
    this.isSettingsOpen = true;
  }

  @action
  closeSettings() {
    this.isSettingsOpen = false;
  }
}

const profileSettingsStore = new ProfileSettingsStore();
export default profileSettingsStore;
