import { FC } from "react";
import { Button } from "../shadcn-components/button";
import jackpassText from "../static/jackpass full logo white.svg";
import jackpassLogo from "../static/logo.png";
import bonfire from "../static/bonfire.jpg";
import bikers from "../static/bikers.jpg";
import boardGame from "../static/board-game.jpg";
import groupTravel from "../static/group-travel.jpg";
import running from "../static/group.jpg";
import football from "../static/football.jpg";
import Banner from "./homepage-banner";
import instagramIcon from "../static/instagram.svg";
import twitterIcon from "../static/twitter.svg";
import { Footer } from "./landing/landing-footer";
import { DownloadCard } from "./download-app-card";

export const AboutUsTemp: FC = () => {
  const adventureOptions = [
    {
      heading: "Football or a running club",
      description:
        "Are you the sporty type? Whether it's football or a running club, we've got you covered.",
      imageUrl: running,
    },
    {
      heading: "Board Game",
      description:
        "Prefer a cozy vibe? Enjoy a cup of coffee and dive into a friendly board game.",
      imageUrl: boardGame,
    },
    {
      heading: "Travel with a group",
      description:
        "Love the outdoors? Embark on a trip to the mountains or beaches with a group of strangers",
      imageUrl: groupTravel,
    },
  ];

  const startYourOwnTribe = (
    <section className="tw-bg-[#e3fe52] tw-py-[50px] sm:tw-py-[100px]">
      <div className="tw-container tw-mx-auto tw-px-4">
        <div className="tw-flex tw-flex-col sm:tw-flex-row tw-items-center tw-justify-center tw-gap-8">
          <div className="tw-w-full sm:tw-w-[460px] tw-h-[383px] tw-overflow-hidden tw-rounded-lg tw-shadow-lg tw-mb-8 sm:tw-mb-0">
            <img
              src={football}
              alt="football"
              className="tw-w-full tw-h-full tw-object-cover"
            />
          </div>
          <div className="tw-w-full sm:tw-w-[460px] tw-flex tw-flex-col tw-justify-center tw-text-left sm:tw-ml-14">
            <h2 className="tw-text-4xl tw-font-bold tw-mb-4 tw-text-left">
              Start your own tribe
            </h2>
            <p className="tw-text-xl tw-mb-6 tw-text-[#888888]">
              Ready to turn your passion into a thriving community? With us, you
              can start your own group, invite others to join, manage it with
              ease, and even monetize your interests.
            </p>
          </div>
        </div>
      </div>
    </section>
  );

  return (
    <div className={`tw-min-h-screen tw-flex tw-flex-col`}>
      <main className="tw-flex-grow tw-text-white">
        <div
          style={{ backgroundImage: `url(${bonfire})` }}
          className="tw-w-full tw-min-h-screen tw-flex tw-flex-col tw-items-center tw-justify-center tw-text-center tw-relative tw-bg-cover tw-bg-center tw-px-4"
        >
          {/* Overlay for opacity */}
          <div className="tw-absolute tw-inset-0 tw-bg-black tw-opacity-50"></div>

          {/* Main content */}
          <div className="tw-relative tw-z-10 tw-w-full">
            <div className="tw-flex tw-items-center tw-justify-center tw-mb-8">
              <img
                src={jackpassText}
                alt="Jackpass"
                className="tw-h-10 tw-text-white"
              />
            </div>
            <h1 className="tw-text-4xl sm:tw-text-6xl tw-font-bold tw-mb-4">
              Build your tribe
            </h1>
          </div>
        </div>
      </main>

      <section className="tw-bg-primary  tw-py-[50px] sm:tw-py-[100px] tw-text-white">
        <div className="tw-container tw-mx-auto tw-px-4">
          <div className="tw-flex tw-flex-col sm:tw-flex-row tw-items-center tw-justify-between">
            <div className="tw-w-full sm:tw-w-[460px] tw-pr-2 tw-flex tw-flex-col tw-justify-center tw-h-full sm:tw-ml-48 tw-mb-8 sm:tw-mb-0">
              <h2 className="tw-text-3xl sm:tw-text-5xl tw-font-bold tw-mb-4 tw-text-left tw-leading-tight">
                Unplug from the digital world to reconnect with the real one.
              </h2>
              <p className="tw-text-lg sm:tw-text-xl tw-mb-6 tw-text-left tw-font-medium tw-leading-8 sm:tw-leading-10">
                Fed up with watching other people's highlight reels? We've got
                you covered. Try a sushi-making class or join a group of bikers
                on an unforgettable trip to Ladakh.
              </p>
            </div>
            <div className="tw-w-full sm:tw-w-[460px] tw-flex tw-justify-center tw-items-center tw-h-full sm:tw-mr-16">
              <div className="tw-relative tw-w-full tw-h-full tw-rounded-2xl tw-overflow-hidden">
                <img
                  src={bikers}
                  alt="Bikers on a trip to Ladakh"
                  className="tw-w-full tw-h-full tw-object-cover tw-rounded-2xl tw-shadow-lg"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="tw-bg-white tw-py-[50px] sm:tw-py-[100px]">
        <div className="tw-container tw-mx-auto tw-px-4">
          <h2 className="tw-text-4xl tw-font-bold tw-mb-12 tw-text-center">
            Pick your Adventure
          </h2>
          <div className="tw-flex tw-flex-col sm:tw-flex-row tw-items-center sm:tw-items-start sm:tw-justify-center tw-gap-16 sm:tw-gap-8">
            {adventureOptions.map((option, index) => (
              <div key={index} className="tw-flex tw-flex-col tw-w-full sm:tw-w-[313px]">
                <img
                  src={option.imageUrl}
                  alt={option.heading}
                  className="tw-w-full tw-h-[400px] sm:tw-h-[313px] tw-object-cover tw-rounded-lg tw-mb-4"
                />
                <h3 className="tw-text-2xl tw-font-bold tw-mb-2 tw-text-left">
                  {option.heading}
                </h3>
                <p className="tw-text-[#888888] tw-text-left tw-text-xl">
                  {option.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {startYourOwnTribe}

      <section className="tw-bg-white tw-py-[50px] sm:tw-py-[100px]">
        <div className="tw-container tw-mx-auto tw-px-0">
          <h1 className="tw-text-4xl tw-font-bold tw-mb-2 tw-text-center">
            Connecting with the right people will change your life
          </h1>
          <Banner speed={50} />
        </div>
      </section>
      <DownloadCard />
      <Footer hideSocials />
    </div>
  );
};
