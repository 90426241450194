export enum ImageType {
  Low = "low",
  Medium = "medium",
  High = "high",
}

const baseUrl = `https://jackpass-backend-staging-images-bucket.s3.ap-south-1.amazonaws.com`;

export const getCommunityBanner = (
  imageUrl: string,
  type: ImageType = ImageType.High
) => {
  return `${baseUrl}/community-banner-images_${type.toString()}/${imageUrl}`;
};

export const getCommunityLogo = (
  imageUrl: string,
  type: ImageType = ImageType.Low
) => {
  return `${baseUrl}/community-logo-images_${type}/${imageUrl}`;
};

export const getEventImage = (
  imageUrl: string,
  type: ImageType = ImageType.High
) => {
  return `${baseUrl}/event-images_${type}/${imageUrl}`;
};

export const getUserImage = (imageUrl: string) => {
  return `${baseUrl}/user-images_low/${imageUrl}`;
};
